<template>

    <div class="h-full w-full flex flex-col relative">
        
        <div class="h-full w-full overflow-hidden relative ">
            
            <loader :loading="loadingSL" />

            <div class="scroll-gradient"></div>

            <div v-if="elementsSL.length > 0" class="h-full overflow-auto relative pb-4 pr-2">

                <div class="box mb-2 w-full flex flex-col" v-for="(i, index) in elementsOrderedSL" :key="index">

                    <div class="flex flex-1 min-h-0 w-full">
                        <div v-if="legendColors[i.id]" class="h-4 mt-1 w-1 mr-3 rounded"
                             :style="'background-color:' + legendColors[i.id].color"></div>
                        <div class="text-2xl">{{ i.name }}</div>
                        <router-link :to="{ name: 'InterIntraExtend', params: { catId: i.name, idSL: i.id }}"
                                     class="flex items-center ml-auto">
                            <div class="header-btn  ml-0"><i class="mdi mdi-chart-donut"></i></div>
                        </router-link>

                        <div class="h-8 w-8 rounded-md flex flex-col justify-center items-center cursor-pointer ml-2"
                            :class="getOpacity(i.id)" @click="toggleSlSelectedItems(i.id)">
                            <i class="mdi mdi-ray-start-arrow"></i>
                        </div>

                    </div>

                    <div class="h-34 flex w-full">

                        <div class="w-1/3 flex flex-col justify-around">
                            <div>
                                <div class="flex items-center">
                                    <div class="text-font-gray">Purchased</div>
                                    <div class="text-2xl ml-auto font-bold">
                                        {{ i.hour_purchased | numberFormat() }} h.
                                    </div>
                                </div>
                                <div class="h-2px bg-gradient-purcashed"></div>
                            </div>
                            <div>
                                <div class="flex items-center">
                                    <div class="text-font-gray">Sold</div>
                                    <div class="text-2xl ml-auto font-bold">
                                        {{ i.hour_sold | numberFormat() }} h.
                                    </div>
                                </div>
                                <div class="h-2px bg-gradient-sold"></div>
                            </div>
                        </div>

                        <div class="flex flex-1 min-w-0 pl-4 pt-2">
                            <div class="w-full h-full">
                                <chart-line-compare
                                        chartId="salesCompare"
                                        :data="[i.amount_purchased, i.amount_sold]"
                                />
                            </div>
                        </div>

                    </div>

                </div>

            </div>

            <div v-else-if="!loadingSL" class="h-full flex flex-col justify-center items-center">
                <span class="module-title text-font-gray">No data</span>
            </div>

        </div>

    </div>

</template>

<script>
    import { state, actions } from '@/store';

    export default {
        name: "SL",
        props:['slList', 'sortBy'],
        data() {
            return {
                loadingSL: false,
                elementsSL: false,
                // sortBy: {name: 'Name', value: 'name', direction: 'DESC'},
                
            }
        },
        methods: {
            load() {
                this.loadingSL = true
                this.axios.get('inter_intra/sl', { params: this.params })
                    .then(response => {
                        this.loadingSL = false
                        this.elementsSL = response.data.data

                        this.$setColorsBySector(this.elementsSL)
                        state.slSelectedItems = []
                    })
            },
            getOpacity(k) {
                if (this.slSelectedItems.includes(k)) {
                    return 'bg-aux-15 text-aux'
                } else {
                    return 'bg-font-gray-15 text-font-gray'
                }

            },
            toggleSlSelectedItems(item) { actions.toggleSlSelectedItems(item) }
        },
        computed: {
            legendColors() { return state.slLegendColors },
            params() { return state.globalParams },
            elementsOrderedSL() {
                if (this.sortBy && this.elementsSL) {
                    return this._.orderBy(this.elementsSL, [this.sortBy.value], [this.sortBy.direction]) }
                else { return this.elementsSL }
            },
            slSelectedItems() { return state.slSelectedItems },
            
        },
        mounted() { 
            this.load();
        },
        watch: {
            params() { this.load() }
        }
    }
</script>